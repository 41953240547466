@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400;1,500;1,700&display=swap);
:root {
  /* Colors: */
  --primary-color-1: #007F5F;
  --primary-color-2: #FFA200;
  --primary-color-3: #1E74BB;
  --primary-color-4: #0F2649;
  --primary-color-5: #EF5033;
  --primary-color-6: #F58649;
  --primary-color-7: #11274A;
  --primary-color-8: #D4D1DA;
  --primary-color-9: #F8F8F9;
  --primary-color-10: #FFFFFF;
  --primary-color-11: #000000;
  --primary-color-12: #212529;
  }

html, body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
